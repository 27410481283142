import React from 'react';
import { useEffect } from 'react';
import './App.css';
import { ZoomMtg } from '@zoom/meetingsdk';

// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.13.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');

function App() {

  // var authEndpoint = 'https://jyrn2f33nk.execute-api.ap-south-1.amazonaws.com/prod/'
  var authEndpoint = 'https://0dpvfe9bue.execute-api.ap-south-1.amazonaws.com/dev/authenticateZoomAccount'
  var sdkKey = '';

  var role = 0;
  //var registrantToken = ''
  var zakToken = ''
 

  const meetingData = new URLSearchParams(window.location.search);
  const meetingNumber = meetingData.get('meetingNumber');
  const passWord = meetingData.get('passWord');
  const userName = meetingData.get('userName');
  const userEmail = meetingData.get('userEmail');
  const registrantToken = meetingData.get('bsmtk')
  const exitUrl = meetingData.get('exitUrl')
  const account = meetingData.get('account')
  var leaveUrl = exitUrl ? exitUrl :'http://localhost:3000'
  console.log(meetingNumber + " " + passWord + " " + userName + " " + userEmail + " " + registrantToken + " "+ exitUrl)

function getSignature(e) {
  //e.preventDefault();

  fetch(authEndpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      meetingNumber: meetingNumber,
      role: role,
      account: account
    })
  }).then(res => res.json())
  .then(response => {
    sdkKey = response.sdkKey;
    startMeeting(response.signature)
  }).catch(error => {
    console.error(error)
  })
}

function startMeeting(signature) {
  document.getElementById('zmmtg-root').style.display = 'block'
  console.log("Trying to start meeting")
  ZoomMtg.init({
      leaveUrl: leaveUrl,
      patchJsMedia: true,
      leaveOnPageUnload: true,
    success: (success) => {
      console.log(success)
      console.log("Init was successfull, will try to join now")
      ZoomMtg.join({
        sdkKey: sdkKey,
        signature: signature,
        meetingNumber: meetingNumber,
        passWord: passWord,
        userName: userName,
        userEmail: userEmail,
        tk: registrantToken,
        zak: zakToken,
        success: (success) => {
          console.log("JOIN was successfull, will try to focus on meeting now")
          console.log(success)
        },
        error: (error) => {
          console.log("I was not able to join")
          console.log(error)
        }
      })

    },
    error: (error) => {
      console.log("I was not able to inititalize")
      console.log(error)
    }
  })
  }

  useEffect(() => {
      getSignature();
  })

return (
  <div className="App">
    <main>
            {/* <h1>Zoom Meeting SDK Sample React</h1> */}

            {/* <button onClick={getSignature}>Join Meeting</button> */}
    </main>
  </div>
);
}

export default App;
